import React, { useRef, useState } from "react";
import {
  Menu,
  Close,
  Dashboard,
  People,
  Construction,
  Category,
  FileDownload,
  Settings,
  ExitToApp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useGetUserDetails, useLogout } from "../apiCalls/userApiCalls";
import useOutsideClick from "../hooks/useOutsideClick";
import { useSelector } from "react-redux";

export default function Navbar() {
  const { currentUser } = useSelector((state) => state.userSlice);
  const token = currentUser.token;
  const userId = currentUser.user._id;

  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef();

  const { mutate: logoutMutate, isLoading: isLogoutLoading } = useLogout();
  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );

  useOutsideClick(userMenuRef, () => {
    if (showUserMenu) setShowUserMenu(false);
  });

  const handleLogout = () => {
    logoutMutate();
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      <nav
        className={`navbar-other px-4 card-shadow rounded-b-3xl fixed w-full z-50 bg-white top-0 hidden md:block md:px-16 lg:px-28 xl:px-28`}
      >
        <div className="items-center justify-between hidden md:flex">
          <Link to="/" className="logo ">
            <img src="/images/logo.png" alt="" className="w-24 py-4" />
          </Link>

          <ul className="flex items-center justify-center gap-4">
            <li className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8">
              <Link to="/" onClick={() => setShowUserMenu(false)}>
                Dashboard
              </Link>
            </li>
            <li className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8">
              <Link to="/users" onClick={() => setShowUserMenu(false)}>
                Users
              </Link>
            </li>
            <li className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8">
              <Link to="/jobs" onClick={() => setShowUserMenu(false)}>
                Jobs
              </Link>
            </li>
            <li className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8">
              <Link to="/jobtypes" onClick={() => setShowUserMenu(false)}>
                Classifications
              </Link>
            </li>
            <li className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8">
              <Link to="/downloadCSV" onClick={() => setShowUserMenu(false)}>
                Payroll CSV
              </Link>
            </li>
            <li className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8">
              <Link to="/settings" onClick={() => setShowUserMenu(false)}>
                Settings
              </Link>
            </li>
            <li
              onClick={handleLogout}
              className="flex items-center gap-4 uppercase font-medium  hover:underline underline-offset-8"
            >
              <Link to="/" onClick={() => setShowUserMenu(false)}>
                {isLogoutLoading ? "Logging out..." : "Logout"}
              </Link>
            </li>
          </ul>

          <Link to="/profile">
            <div
              className="w-10 h-10 border border-gray-200  rounded-full"
              style={{
                backgroundImage: `url("${userDetails?.data.data.imgUrl}"), url("${fallbackImage}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Link>
        </div>
      </nav>

      <nav
        className={`navbar-mbl md:hidden px-6 pb-2 card-shadow rounded-b-3xl fixed z-50 w-full bg-white top-0`}
      >
        <div className="flex items-center justify-between">
          <Link to="/profile">
            <div
              className="w-10 h-10 border border-gray-200  rounded-full"
              style={{
                backgroundImage: `url("${userDetails?.data.data.imgUrl}"), url("${fallbackImage}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Link>
          <Link to="/" className="logo">
            <img src="/images/logo.png" alt="" className="w-24 py-4" />
          </Link>
          <div
            onClick={() => setShowUserMenu(!showUserMenu)}
            className="hamburger h-10 w-10 flex items-center justify-end"
          >
            <button>
              <Menu style={{ fontSize: "25" }} />
            </button>
          </div>
        </div>
      </nav>

      <div
        ref={userMenuRef}
        className={`${
          showUserMenu ? "" : "hidden"
        } sideMenu px-6 pb-2 bg-white card-shadow fixed z-50 w-full top-0 rounded-b-3xl`}
      >
        <div className="flex items-center justify-between">
          <div className="h-10 w-10"></div>
          <Link to="/" className="logo">
            <img src="/images/logo.png" alt="" className="w-24 py-4" />
          </Link>
          <div
            onClick={() => setShowUserMenu(false)}
            className="hamburger h-10 w-10 flex items-center justify-end"
          >
            <Close style={{ fontSize: "28" }} />
          </div>
        </div>
        <ul className="space-y-6 pl-12 pt-10 pb-4">
          <li className="flex items-center gap-4 uppercase font-medium ">
            <Dashboard style={{ fontSize: "18px" }} />
            <Link to="/" onClick={() => setShowUserMenu(false)}>
              Dashboard
            </Link>
          </li>

          <li className="flex items-center gap-4 uppercase font-medium ">
            <People style={{ fontSize: "18px" }} />
            <Link to="/users" onClick={() => setShowUserMenu(false)}>
              Users
            </Link>
          </li>
          <li className="flex items-center gap-4 uppercase font-medium ">
            <Construction style={{ fontSize: "18px" }} />
            <Link to="/jobs" onClick={() => setShowUserMenu(false)}>
              Jobs
            </Link>
          </li>
          <li className="flex items-center gap-4 uppercase font-medium ">
            <Category style={{ fontSize: "18px" }} />
            <Link to="/jobtypes" onClick={() => setShowUserMenu(false)}>
              Classifications
            </Link>
          </li>
          <li className="flex items-center gap-4 uppercase font-medium ">
            <FileDownload style={{ fontSize: "18px" }} />
            <Link to="/downloadCSV" onClick={() => setShowUserMenu(false)}>
              Payroll CSV
            </Link>
          </li>
          <li className="flex items-center gap-4 uppercase font-medium ">
            <Settings style={{ fontSize: "18px" }} />
            <Link to="/settings" onClick={() => setShowUserMenu(false)}>
              Settings
            </Link>
          </li>

          <li
            onClick={handleLogout}
            className="flex items-center gap-4 uppercase font-medium"
          >
            <ExitToApp style={{ fontSize: "18px" }} />
            <Link to="/" onClick={() => setShowUserMenu(false)}>
              {isLogoutLoading ? "Logging out..." : "Logout"}
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
